export const API_URL = "https://api.secondstage.app"
export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#004b9b', '#004b9b'],
        home_question: ['#004b9b', '#004b9b'],
        applausometro: ['#004b9b', '#004b9b'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#004b9b', '#004b9b'],
        qna: ['#004b9b', '#004b9b'],
    },
    primary: '#004b9b',
    secondary: '#263238',
    accent: '#004b9b',
    text: '#eceff1',
    background:"#11112C",
    poll:{
        default:"#004b9b",
        answered:"#fdc002",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}