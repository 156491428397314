import React from "react";
import {alpha, Grid, List, ListItem, Stack, styled, Typography} from "@mui/material";
import {THEME_CONFIG} from "../../../config";

const {poll, text} = THEME_CONFIG

export default function QuizQuestion({elementId, risposte, corretta, answer, sendAnswer, isPoll}) {
    const RispostaStyled = styled(ListItem)({
        border: `2px solid ${poll.default}`,
        borderRadius: '0.7rem',
        background: poll.default,
        //margin: '1rem 0',
        cursor: 'pointer',
    })

    const computeBkg = (r) => {
        if (!isPoll) {
            if (answer !== '') {
                if (corretta === r) {
                    return poll.correct
                } else if (answer === r && answer !== corretta) {
                    return poll.wrong
                }
            }
        } else {
            if (answer === r) {
                return poll.answered
            }
        }

    }

    const Risposte = () => (
        risposte.map((r, i) => {
            const isPicturesPoll = !!r?.url
            return <Grid key={i} item xs={isPicturesPoll ? 6 : 12}
                  sx={{
                      scale: isPicturesPoll ? answer ? answer === r?.label ? '1.05' : '0.9' : '1' : 'inherit',
                      opacity: isPicturesPoll ? answer ? answer === r?.label ? '1' : '0.5' : '1' : 'inherit',
                  }}>
            <RispostaStyled key={i} style={{
                width: '100%',
                background: computeBkg(r?.label || r),
                border: computeBkg(r?.label || r) ? '2px solid ' + alpha(text, 0.5) : `2px solid ${poll.default}`,
                color: 'white',
                margin: isPicturesPoll ? 'inherit' : '1rem 0',
            }} onClick={() => rispondi(r?.label || r)}>
                <Stack>
                    {
                        isPicturesPoll &&
                            <img
                                src={r?.url}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '0.5rem',
                                    //paddingBottom: '0.7rem'
                                }}/>
                    }
                    <Typography variant={isPicturesPoll ? "caption" : 'h6'} sx={{ pt:1 }}>
                        {r?.label || r}
                    </Typography>
                </Stack>
            </RispostaStyled>
            </Grid>
        })
    )

    const rispondi = (r) => {
        if (isPoll) {
            sendAnswer(r)
        } else {
            if (answer === '') {

                sendAnswer(r)
            }
        }
    }

    const isPicturesPoll = risposte && risposte[0] && !!risposte[0]?.url

    return (
        <List>
            {isPicturesPoll ?
                <Grid container spacing={1} justifyContent={'center'}>
                    <Risposte/>
                </Grid>
            : <Risposte/>}
        </List>
    )
}
